import React from 'react'

const AnimationComponent = () => {
    return (
        <div class="main">
            <h1>IHM SUPPLIER DASHBOARD <div class="roller">
                <span id="rolltext">2000+ Vessels<br />
                    150,000+ MD's<br />
                    7500+ Suppliers<br />
                    1 Million+ Orders<br />
                    {/* <span id="spare-time">Over 1 Million PO's Processed</span><br /> */}
                </span>
            </div>
            </h1>
        </div>
    );
};

export default AnimationComponent