export const homeObjTwo = {
    id: 'whyus',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine1: 'Why us?',
    headline1: 'What makes people place their trust in us?',
    description1: 'At Verifavia Shipping, we believe that sustainable procurement is key to creating a better environment for everyone. We understand that engaging in sustainable practices is not only the right thing to do, but also the most cost-effective and efficient approach to business.',
    description2: 'Our commitment to sustainability begins with the products that we use and the processes that we follow. We are committed to reducing the carbon footprint and doing our part to create a healthier and more sustainable world.',
    description3: 'We are able to create an effective digital strategy by using only the latest technology and coding languages to make sure that our processes are up to date. Moreover, we have an extensive library of content that can be quickly and easily integrated. Plus, our customer support team is available 24/7, to assist you with any queries or concerns you may have.',
    description4: 'It’s why we do what we do, and why we invite you to join us in the journey to a healthier and brighter future.',
    description5: 'We are a team comprising naval architects and marine engineers with prior procurement experience in shipping companies, showcasing the following numbers:',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/svg-1.svg'),
    alt: 'Expert',
    dark: true,
    primary: true,
    darkText: true,
};