import React from 'react'
import Icon1 from '../../../images/svg-8.svg'
import Icon2 from '../../../images/svg-9.svg'
import Icon3 from '../../../images/svg-10.svg'
import { useState } from 'react';

import {
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP
} from './FeatureCardsElements'
const FeatureCards = () => {
    return (
        <>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Easy To Use</ServicesH2>
                    <ServicesP>Effortless usability with intuitive simplicity</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>Cost Effective</ServicesH2>
                    <ServicesP>Achieve maximum value for money spent</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Domain Expertise</ServicesH2>
                    <ServicesP>In-depth knowledge within a field</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </>
    )
}

export default FeatureCards