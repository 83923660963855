export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine1: 'About IHM Supplier dashboard',
    headline1: 'A User-Friendly Shipping Procurement Solution with Expert Support',
    description1: 'Developed by a team of hazmat experts and marine engineers with shipping procurement experience.',
    description2: 'Highly user-friendly',
    description3: 'Comes with user guides and round-the-clock assistance',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/svg-1.svg'),
    alt: 'Expert',
    dark: true,
    primary: true,
    darkText: false,


    topLine2: 'ABOUT IHM SUPPLIER ADMIN DASHBOARD',
    headline2: 'Capabilities of a Software Solution for Shipbuilding and IHM Compliance',
    description4: 'The MD and Suppliers Declaration of Conformity can be generated effortlessly for multiple orders, ensuring compliance with regulations and guidelines.',
    description5: 'API integration with multiple procurement systems.',
    description6: 'Catalog Review for IHM Relevance.',
};