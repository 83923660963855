import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: #204280;

    @media screen and (max-width : 768px) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: left;

`;

export const InfoRow = styled.div`
    display: grid;
    align-items: left;
    margin-top: 5rem;
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    width: 100%;
`;


export const TextWrapper = styled.div`
    max-width: 100%;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: #2C8BB0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 16px;
`;

export const Heading = styled.h2`
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 1.1;
    font-weight: 600;
    color: #f7f8fa;

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    max-width: 100%;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 3.5rem;
`;


export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;

export const DescriptionWrapper = styled.div`
  overflow: hidden;
`;

export const DescriptionText = styled.p`
max-width: 100%;
margin-bottom: 24px;
font-size: 18px;
line-height: 24px;
color: #f7f8fa;
`;

export const CardIntro = styled.p`
max-width: 100%;
margin-bottom: 24px;
font-size: 18px;
line-height: 24px;
font-weight: 700;
color: #fff;
`;

export const ReadMoreButton = styled.button`
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
`;

export const ReadLessButton = styled.button`
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
`;

