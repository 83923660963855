import React, { useState, useEffect } from 'react';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  Heading,
  DescriptionWrapper,
  DescriptionText,
  VideoBg,
} from './OurPresenceElements';
import verifaviaMap from '../../images/our_presence_map.webm';

const OurPresence = ({ id, heading, description }) => {
  const [typedText, setTypedText] = useState("");
  const text = "The rest by Verifavia Community";

  useEffect(() => {
    let currentIndex = 0;
    let isLooping = true;

    const typeText = () => {
      const intervalId = setInterval(() => {
        if (currentIndex >= text.length) {
          clearInterval(intervalId);

          // Wait for 2 seconds before starting the typing effect again
          setTimeout(() => {
            if (isLooping) {
              currentIndex = 0;
              setTypedText("");
              typeText();
            }
          }, 2000);
        } else {
          setTypedText(text.slice(0, currentIndex + 1));
          currentIndex++;
        }
      }, 50);
    };

    typeText();

    return () => {
      isLooping = false;
    };
  }, []);

  return (
    <InfoContainer>
      <InfoWrapper id={id}>
        <InfoRow>
          <Column1>
            <TextWrapper>
              {/* <Heading>{heading}</Heading> */}
              <DescriptionWrapper>
                <DescriptionText>Two-thirds of the Earth is covered by water. </DescriptionText>
                <Heading>{typedText}!</Heading>
              </DescriptionWrapper>
            </TextWrapper>
              <VideoBg autoPlay loop muted src={verifaviaMap} type='video/webm' />
          </Column1>
        </InfoRow>
      </InfoWrapper>
    </InfoContainer>
  );
};

export default OurPresence;
