import React, { useState } from 'react';
import Video from '../../videos/video.webm';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import { Button } from '../ButtonElements';
import AnimationComponent from './RollerAnimation/index'
const HeroSection = () => {

  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/webm' />
      </HeroBg>
      <HeroContent>
      <AnimationComponent />

        {/* <HeroH1>IHM Supplier Dashboard</HeroH1> */}
        <HeroP>
          Generate your Material Declarations (MDs) and Supplier’s Declaration of Conformity (SDoCs)
          for the purchase orders of the vessels above 500 GRT for our mutual clients.
        </HeroP>
        {/* <HeroP>
          The dashboard will create and download ‘item-wise’ and ‘order-wise’ declarations
          for multiple orders within a few clicks and be in compliance with the EU regulations and IMO guidelines
        </HeroP> */}
        {/* <HeroP>
          {typedText1}
        </HeroP>
        <HeroP>
          {typedText1}
        </HeroP>
        <HeroP>
          {typedText1}
        </HeroP>
        <HeroP>
          {typedText1}
        </HeroP> */}
    
        <HeroBtnWrapper>
          {/* <Button to="demo" onMouseEnter={onHover}
            onMouseLeavte={onHover} primary='true' dark='true'>
            Request a Demo {hover ? <ArrowForward /> : <ArrowRight />}
          </Button> */}
        </HeroBtnWrapper>
   
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection