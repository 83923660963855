import React from 'react'
import Icon1 from '../../../images/svg-3.svg'
import Icon2 from '../../../images/svg-2.svg'
import Icon3 from '../../../images/svg-5.svg'
import Icon4 from '../../../images/svg-6.svg'
import Icon5 from '../../../images/svg-7.svg'
import Icon6 from '../../../images/svg-12.svg'
import useTypingEffect from '../TypingEffect/index';
import { useState } from 'react';

import {
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP
} from './CardSectionElements'

const CardSection = () => {
    const shortDescriptions = [
        '',
        '',
        '',
        '',
        ''
    ];
    const longDescriptions = [
        '2000+ vessels under IHM Maintenance contract.',
        '1 million+ POs Processed, under IHM Scope - 15%.',
        'Collected 150,000+ MDs (Material Declarations) & SDoCs (Supplier Declaration of Conformity).',
        'Empowering Success: Serving 80+ Satisfied Clients and Counting',
        '5000+ Suppliers use Supplier Dashboard to generate these declarations, saving them plenty of effort and time.',
        'Through our round-the-clock assistance, we have saved numerous suppliers from being blacklisted and prevented shipowners from facing costly penalties during PSC Inspections, while our effective digital strategy, powered by the latest technology and coding languages, enables us to offer up-to-date processes and integrate an extensive library of content seamlessly, and our 24/7 customer support team is always ready to assist you with any queries or concerns.'
    ];

    const [expandedDescriptions, setExpandedDescriptions] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
    });

    const toggleDescription = (index) => {
        setExpandedDescriptions(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const buttonStyles = {
        background: 'none',
        color: 'black',
        border: 'none',
        padding: '8px 16px',
        cursor: 'pointer',
    }

    const numberHighlights = {
        color: 'black',
    }

    

    const typedText1 = "2000";
    const typedText2 = "1000000";
    const typedText3 = "150000";
    const typedText4 = "80";
    const typedText5 = "5000";
    const typedText6 = "24";


    return (
        <>
            <ServicesWrapper>
            <ServicesCard>
                    <ServicesIcon src={Icon4} />
                    <ServicesH2><span style={numberHighlights}>{typedText4}+</span> Clients</ServicesH2>
                    <ServicesP>{expandedDescriptions[3] ? longDescriptions[3] : shortDescriptions[3]}<br /><button onClick={() => toggleDescription(3)} style={buttonStyles}>{expandedDescriptions[3] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2><span style={numberHighlights}>{typedText1}+</span> Vessels</ServicesH2>
                    <ServicesP>{expandedDescriptions[0] ? longDescriptions[0] : shortDescriptions[0]}<br /><button onClick={() => toggleDescription(0)} style={buttonStyles}>{expandedDescriptions[0] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2><span style={numberHighlights}>1 Million+</span> POs Processed</ServicesH2>
                    <ServicesP>{expandedDescriptions[1] ? longDescriptions[1] : shortDescriptions[1]}<br /><button onClick={() => toggleDescription(1)} style={buttonStyles}>{expandedDescriptions[1] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2><span style={numberHighlights}>{typedText3}+</span> MDs Collected</ServicesH2>
                    <ServicesP>{expandedDescriptions[2] ? longDescriptions[2] : shortDescriptions[2]}<br /><button onClick={() => toggleDescription(2)} style={buttonStyles}>{expandedDescriptions[2] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon5} />
                    <ServicesH2><span style={numberHighlights}>{typedText5}+</span> Suppliers</ServicesH2>
                    <ServicesP>{expandedDescriptions[4] ? longDescriptions[4] : shortDescriptions[4]}<br /><button onClick={() => toggleDescription(4)} style={buttonStyles}>{expandedDescriptions[4] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon6} />
                    <ServicesH2><span style={numberHighlights}>24x7</span> Assistance</ServicesH2>
                    <ServicesP>{expandedDescriptions[5] ? longDescriptions[5] : shortDescriptions[5]}<br /><button onClick={() => toggleDescription(5)} style={buttonStyles}>{expandedDescriptions[5] ? 'Read Less' : 'Read More'}</button></ServicesP>
                </ServicesCard>
            </ServicesWrapper>
        </>
    )
}

export default CardSection
