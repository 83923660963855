import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient(
        108deg,
        rgba(10, 32, 66, 128) 0%,
        rgba(202, 6, 1) 100%
    );
`;
export const FormWrap = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 400px) {
            height: 80%;
        }
`;

export const Icon = styled(Link)`
        margin-left: 32px;
        margin-top: 32px;
        text-decoration: none;
        color: #fff;
        font-weight: 700;
        font-size: 32px;

        @media screen and (max-wifth: 480px) {
            margin-left: 16px;
            margin-top: 8px;
        }
`;

export const FormContent = styled.div`
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media screen and (max-width: 480px) {
            padding: 10px;
        }
`;

export const Form = styled.form`
        max-width: 450px;
        height: auto;
        width: 100%;
        z-index: 1;
        display: grid;
        margin: 0 auto;
        border-radius: 4px;


        @media screen and (max-width: 400px) {
            padding: 32px 32px;
        }
`;

export const FormFields = styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 18px;
`;
export const FormItem = styled.div`
`;
export const Message = styled.div`
        margin-top: 1rem;
`;

export const FormH1 = styled.h1`
        margin-bottom: 30px;
        color: #fff;
        font-size: 20px;
        font-weight: 400px;
        text-align: center;
`;

export const FormLabel = styled.label`
        margin-bottom: 10px;        
        font-size: 14px;
        color: #fff;
`;

export const FormInput = styled.input`
        margin-top:0.5rem;
        padding: 12px 12px;
        width: 100%;
        border: none;
        border-radius: 4px;

`;
export const MsgInput = styled.textarea`
        margin-top:0.5rem;
        padding: 12px 12px;
        width: 100%;
        border: none;
        border-radius: 4px;
`;

export const FormButton = styled.button`
        background: #000;
        padding: 10px 0;
        border: none;
        border-radius: 4px;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        margin-top: 1.5rem;
`;

export const Text = styled.div`
        text-align: center;
        margin-top: 24px;
        color: #fff;
        font-size: 14px;
`;